import { graphql, PageProps } from 'gatsby'
import styled from 'styled-components'

import {
  SectionContent,
  Tabs,
  TabLink,
  ButtonLinkExternal,
  TitleLarge
} from '../../common/styles'
import colors from '../../constants/colors'
import spacing from '../../constants/spacing'
import Layout from '../../layouts'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Content = styled(SectionContent)`
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: ${spacing.MAIN_PADDING};
  font-size: 16px;
`

const Text = styled.div`
  line-height: 21px;
`

const VimeoLink = styled(ButtonLinkExternal).attrs({ color: '#39ACEA' })`
  margin: ${spacing.MAIN_PADDING} 0;
`

const Title = styled(TitleLarge)`
  margin-top: 28px;
  padding-bottom: 0;
  padding-right: 7px;
  padding-left: 7px;
  color: white;
`

const Portfolio = ({
  data: {
    allStrapiPortfolio: { edges: allStrapiPortfolio },
    strapiGlobal
  }
}: PageProps<Queries.VideoPortFolioQuery>) => {
  const vimeoURL = strapiGlobal?.socialNetworks?.find(
    socialNetwork => socialNetwork?.title === 'Vimeo'
  )?.url!

  return (
    <Layout
      dark
      smallNavBar
      showCTA
      seo={{
        title: 'Portfolio vidéo',
        description:
          "Vous trouverez sur cette page un lien vers les vidéos professionnelles auxquelles j'ai eu la chance de pouvoir participer (tournages, clips...)."
      }}
    >
      <Container>
        <Tabs>
          {[...allStrapiPortfolio]
            .sort((a, b) => Number(a.node.index) - Number(b.node.index))
            .map(({ node: { id, path, title } }) => (
              <TabLink key={id} to={`/portfolio/${path}/`}>
                {title}
              </TabLink>
            ))}
          <TabLink to='/portfolio/video/'>Vidéos</TabLink>
        </Tabs>
        <Title>Portfolio: Vidéo</Title>
        <Content>
          <Text>Découvrez dès maintenant mon portfolio vidéo sur&nbsp;:</Text>
          <VimeoLink href={vimeoURL} target='_blank' extraLarge>
            Vimeo
          </VimeoLink>
        </Content>
      </Container>
    </Layout>
  )
}

export default Portfolio

export const query = graphql`
  query VideoPortFolio {
    allStrapiPortfolio {
      edges {
        node {
          id
          title
          path
          index
        }
      }
    }
    strapiGlobal(socialNetworks: { elemMatch: { title: { eq: "Vimeo" } } }) {
      id
      socialNetworks {
        url
        title
      }
    }
  }
`
